
.page__title{
    width: 100%;
    display: flex;
    margin-top: 100px;
    color: white;
    justify-content: center;
    align-items: center;
    font-size: 100px;
    height: fit-content;
    font-family: 'Bebas Neue', serif;
}
.page__title > p {
    margin-top: 0px;
    margin-bottom: 0px;
}

@media (max-width: 1360px){
    .staff__row{
        display: grid;
        grid-template-areas:
        'title image'
        'info image';
        width: 650px;
        margin-top: 75px;

    }
    .staff__page{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .staff__title{
        color: white;
        grid-area: title;
        background-color: rgba(75, 75, 75, 0.392);
        height: 100px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        font-style: italic;
        text-align: start;
    }
    .staff__title > p {
        width: 100%;
    }

    .staff__info{
        width: 400px;
        color: white;
        grid-area: info;
        background-color: rgba(75, 75, 75, 0.392);
        height: 200px;
        padding-left: 10px;
        font-size: 22px;
    }

    .staff__image{
        grid-area: image;
        background-color: rgba(75, 75, 75, 0.392);
        width: 250px;
        height: 300px;
    }
}

@media (min-width: 1360px){
    .staff__page{
        display: grid;
        grid-template-columns: 650px 650px;
        justify-content: center;
        /* row-gap: 150px; */
        column-gap: 50px;

        width: 100%;
    }
    .staff__row{
        display: grid;
        grid-template-areas:
        'title image'
        'info image';
        width: 100%;
        margin-top: 100px;
    }
    .staff__title{
        display: flex;
        color: white;
        grid-area: title;
        /* background-color: red; */
        background-color: rgba(75, 75, 75, 0.392);
        height: 100px;
        width: 400px;
        font-size: 25px;
        justify-content: center;
        align-items: center;
    }

    .staff__title > p {
        width: 100%;
    }

    .staff__info{
        color: white;
        grid-area: info;
        /* background-color: grey; */
        background-color: rgba(75, 75, 75, 0.392);
        height: 200px;
        padding-left: 10px;
        font-size: 22px;
    }

    .staff__image{
        grid-area: image;
        color: white;
        background-color: rgba(75, 75, 75, 0.392);
        width: 250px;
        height: 300px;
    }
}
