.calendar__component{
    height: 100%;
    width: 100%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}



.calendar__page{
    display: grid;
    height: fit-content;
    width: 1130px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    background-color: rgb(37, 37, 37);
}


.calendar__day{
    height: 100px;
    border: 1px solid #f4f4f425;
    color: white;
    font-size: 15px;
    padding-left: 2px;
    position: relative;
}
.calendar__day > p{
    right: 5px;
}
.today__date > p{
    display: flex;
    background-color: rgb(160, 25, 25);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}

.calendar__month{
    display: flex;
    justify-content: start;
    flex-direction: row;
    width: 1130px;
    font-size: 30px;
    color: white;
    align-items: end;
    margin-top: 35px;
}

.calendar__month > .month{
    font-weight: bold;
    margin-right: 10px;
    padding-bottom: 10px;
    margin-bottom: 0px;


}
.calendar__month > .year{
    padding-bottom: 10px;
    margin-bottom: 0px;
}
.calendar__month > .title{
    position: absolute;
    color: white;
    font-family: 'Bebas Neue', serif;
    font-size: 50px;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%);
    border-bottom: 2px solid rgb(160, 25, 25);
}

.calendar__week{
    width: 1130px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    color: white;
    height: 40px;
    background-color: rgb(80, 28, 28);
}
.calendar__week > p{
    width: auto;
}
.weekend__day{
    background-color: rgb(29, 29, 29);
}

.calendar__event__form{
    background-color: rgb(66, 65, 65);
    border-radius: 9px;
    border: 1px solid rgb(169, 169, 169);
    height: 110px;
    width: 200px;
    position: absolute;
    right: -120%;
    top: -15%;
    align-self: center;
    display: flex;
    justify-content: space-evenly;
    align-items: start;
    flex-direction: column;
    z-index: 3;
}

.calendar__event__point{
    position: absolute;
    width: 0;
    height: 0;
    top: 30%;
    left: -20px;
    border-top: 25px solid transparent;
    border-right: 50px solid rgb(66, 65, 65);
    border-bottom: 25px solid transparent;

}
.calendar__event__form > p{
    margin-bottom: 0px;
    margin-top:5px;
    margin-left: 10px;

}
.calendar__event__form > input{
    background-color: rgb(66, 65, 65);
    z-index: 3;
    font-size: 20px;
    color: white;
    width: 90%;
    border: 0;
    margin-left: 10px;
}
input:focus{
    outline:none!important;
}
input::placeholder {
    color: grey;
    font-size: 20px;

}
.form__divider{
    width: 100%;
    height: 1px;
    background-color: white;
    z-index: 3;
}

.form__shadow{
    position: fixed;
    background-color: rgba(0, 0, 0, 0);
    z-index: 1;
    height: 100vh;
    width: 100vw;
    top:0px;
    left: 0px;
}

.form__submit{
    position: absolute;
    top: 5px;
    right: 5px;
    padding:5px;
    background-color: white;
    color: black;
    border-radius: 7px;
    cursor: pointer;
}
.form__submit:hover{
    background-color: rgb(37, 37, 37);
    color: white;
}


.calendar__event{
    position: relative;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgba(255, 0, 0, 0.436);
    height: fit-content;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 0px;
    margin-bottom: 5px;
    border-radius: 7px;
    font-size: 12px;
}
.calendar__event:hover{
    background-color: rgba(178, 0, 0, 0.436);
}

.calendar__event > p{
    margin-top: 0px;
    margin-bottom: 0px;
}
.event__title{
    justify-self: start;
}
.event__time{
    justify-self: end;
}
 .calendar__events{
    height: 50px;
    position: relative;
    overflow-y: auto;
 }

 .event__update__form{
    z-index: 4;
    position: fixed;
    height: fit-content;
    width: 350px;
    display: flex;
    /* justify-content: space-evenly; */
    flex-direction: column;
    background-color: rgb(66, 65, 65);
    border-radius: 7px;
    border: 1px solid white;
 }
.event__update__form > input{
    height: 20px;
    background-color: rgb(66, 65, 65);
    z-index: 3;
    font-size: 20px;
    color: white;
    width: 90%;
    border: 0;
    margin-left: 15px;
}
.event__update__form > p{
    font-size: 35px;
    color: white;
    font-family: 'Bebas Neue', serif;
    align-self: center;
}

.event__update__form > label{
    font-size: 26px;
    color: white;
    margin-left: 15px;
}

.close__event{
    position: absolute;
    height: fit-content;
    width: fit-content;
    font-size: 23px;
    align-self: end;
    color: white;
}

.event__update__submit{
    cursor: pointer;
    margin-top: 5px;
    color: white;
    position: absolute;
    align-self: end;
    justify-self: end;
    bottom: 0px;
    background-color: rgb(42, 42, 42);
    border: 1px solid white;
    border-radius: 5px;
    padding: 5px;
}
.event__delete{
    color: white;
    cursor: pointer;
    bottom: 0px;
    background-color: rgb(42, 42, 42);
    border: 1px solid white;
    border-radius: 5px;
    padding: 5px;
    width: fit-content;
    margin-top: 15px;
}
