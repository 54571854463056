.change__image__page{
    z-index: 6;
    background-color: rgba(137, 136, 136, 0.759);
    display: grid;
    /* grid-template:  100px / repeat(100, 150px) 45px; */

    column-gap: 10px;
    overflow: auto;
    position: fixed;
    width: 40vw;
    padding-top: 40px;
    padding-left: 40px;
    padding-bottom: 40px;

    /* padding-right: 10px; */
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: -20vw;
    border-radius: 8px;
}

.shadow__background{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.485);
    z-index: 4;
}

.image__delete{
    color: rgb(251, 0, 0);
}

.image__delete:hover{
    color: rgb(156, 0, 0);
}

.image__close{
    color: rgb(99, 153, 255);
    position: fixed;
    margin-left: 5px;
    margin-top: 5px;
    font-size: 25px;
    /* left: 0;
    top: 0; */
    left: 32%;
    top: 40%;
    transform: translate(-50%, -50%);

}
.image__close:hover{
    color: rgb(62, 117, 219);

}

.image__add{
    color: rgb(76, 76, 76);
    justify-self: start;
    align-self: center;
    font-size: 35px;
    padding-left: 30px;
}

.image__add:hover{
    color: white;
}
