.navbar__component{
    position: fixed;
    z-index: 5;
    grid-template-columns: repeat(auto-fill, 1fr);
    background-color: rgb(0, 0, 0);
    margin: 0px;
    top: 0px;
    height: 50px;
    width: 100%;
}

.fa-bars{
    color: white;
    font-size: 25px;
    margin-top: 10px;
}
.fa-bars:hover{
    color: rgb(144, 144, 144)
}

@keyframes slideFromLeft{
    0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(0);
      }
}

@keyframes slideOut{
    0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-100%);
      }
}

.slide__in{
    animation: .5s ease-out 0s 1 slideFromLeft;
}

.slide__out{
    animation: .5s ease-out 0s 1 slideOut;

}

.more__page{
    position: fixed;
    /* background-color: rgba(31, 53, 66, 0.765); */
    background-color: rgba(0, 0, 0, 0.765);
    z-index: 3;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border: rgba(255, 255, 255, 0.683) solid 1px;
    top: 20%;
    left: 0%;
    right: 0%;
    height: fit-content;
    width: 40%;
    display: grid;
    font-family:  "Andale Mono", monospace;
    grid-template-areas:
    '. . x'
    'h h h'
    'a a a'
    'c c c'
    's s s'
    'cal cal cal'
    '. . .'
    'l l l';
}
.more__background{
    background-color: rgba(0, 0, 0, 0);
    position: fixed;
    z-index: 2;
    height: 100vh;
    width: 100vw;
    top: 0px;
    left: 0px;
}

.more__logout{
    color: white;
    font-size: 30px;
    grid-area: l;
    padding-top: 25px;
    border-top: white solid 1px;
    /* text-align: center; */
    padding-left: 35px;
    height: fit-content;
}
.more__logout:hover{
    color: rgb(0, 90, 125);
    border-top: rgb(197, 197, 197) solid 1px;
}

div > .selected{
    color: rgb(0, 120, 167);
}

.more__home{
    color: white;
    padding-bottom: 5px;
    font-size: 27px;
    grid-area: h;
    border-bottom: white solid 1px;
    text-decoration: none;
    /* text-align: center; */
    padding-left: 35px;
    padding-top: 20px;
    /* height: fit-content; */

}
.more__home:hover{
    color: rgb(0, 90, 125);
    border-bottom: rgb(197, 197, 197) solid 1px;
}

.fa-times{
    grid-area: x;
    color: white;
    font-size: 21px;
    margin-right: 5px;
    height: fit-content;
    width: fit-content;
    justify-self: end;
}

.fa-times:hover{
    color: rgb(190, 190, 190)
}

.more__about{
    color: white;
    padding-bottom: 5px;

    font-size: 27px;
    padding-top: 20px;
    grid-area: a;
    border-bottom: white solid 1px;
    text-decoration: none;
    /* text-align: center; */
    padding-left: 35px;
    height: fit-content;
}
.more__about:hover{
    color: rgb(0, 90, 125);
    border-bottom: rgb(197, 197, 197) solid 1px;
}


.more__contact{
    color: white;
    padding-bottom: 5px;
    border-bottom: white solid 1px;
    font-size: 27px;
    padding-top: 20px;
    grid-area: c;
    padding-left: 35px;
    text-decoration: none;
    /* text-align: center; */
    height: fit-content;
    top: 0px;
}
.more__contact:hover{
    color: rgb(0, 90, 125);
    border-bottom: rgb(197, 197, 197) solid 1px;
}

.more__staff{
    color: white;
    padding-bottom: 5px;

    font-size: 27px;
    padding-top: 20px;
    grid-area: s;
    border-bottom: white solid 1px;
    text-decoration: none;
    /* text-align: center; */
    padding-left: 35px;
    height: fit-content;
}
.more__staff:hover{
    color: rgb(0, 90, 125);
}

.more__calendar{
    color: white;
    padding-bottom: 5px;

    font-size: 27px;
    padding-top: 20px;
    grid-area: cal;
    border-bottom: white solid 1px;
    text-decoration: none;
    /* text-align: center; */
    padding-left: 35px;
    height: fit-content;
}
.more__calendar:hover{
    color: rgb(0, 90, 125);
}
