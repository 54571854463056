.login__form{
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(246, 246, 246);
    border: 2px solid rgb(48, 147, 171);
    border-radius: 8px;
}

.login__form > label{
    margin-top: 20px;
}

body{
    background-image: url("http://cdn.shopify.com/s/files/1/0250/5422/files/ios_linen_texture___black_by_vegardhw-d3ddll5_copy.jpg?2614");
    background-repeat: repeat-y;
}
