@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

html {
    scroll-behavior: smooth;
  }

.home__page{
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    margin-top: 50px;
}

.home__page__content{
    height: fit-content;
    width: 100%;
    background-image: url("http://cdn.shopify.com/s/files/1/0250/5422/files/ios_linen_texture___black_by_vegardhw-d3ddll5_copy.jpg?2614");
    background-repeat: repeat-y;
    color: white;
    z-index: 2;
    position: absolute;
    margin-top: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
}

.home__page__information{
    width: 100%;
    background-color: rgba(100, 148, 237, 0.686);
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.information__tabs.location:hover{
    color: rgb(202, 202, 202);
}

.information__tabs{
    padding-top: 10px;
    padding-bottom: 10px;
    width: 200px;
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
}
.information__text{
    font-size: 20px;
}
.information__text.live_stream{
    text-decoration: underline;
    color: inherit;
}
.information__text.location{
    text-decoration: underline;
    color: inherit;

}
.information__icon{
    font-size: 30px;
    margin-right: 10px;
    /* color: white; */
}

.inner__stuff{
    height: fit-content;
    color: rgb(255, 255, 255);
    padding: 50px;
    font-size: 25px;
    background-color: rgb(49, 49, 49);
    border-radius: 7px;
    margin-top: 10px;
}

.slideshow__component{
    position: absolute;
    display: grid;
    width: 100%;
    height: 500px;
    grid-template-areas:
    "phone phone phone"
    ". message ."
    ". contact ."
    "leftA array rightA";
    background-color: rgba(255, 255, 255, 0);
}


.slide__array{
    grid-area: array;
    position: absolute;
    z-index: 4;
    justify-self: center;
}

.fa-circle{
    color: white;
    font-size: 20px;
}
.fa-circle:hover{
    color: rgb(163, 161, 161);
}
@media (max-width: 1100px){

    .slide__image{
        position: fixed;
        z-index: 1;
        width: 100%;
        min-height: 500px;
        /* bottom: 50px; */
        /* overflow: hidden; */
    }
}
@media (min-width: 1100px){

    .slide__image{
        position: fixed;
        z-index: 1;
        width: 100%;
        min-height: 500px;
        /* bottom: 100px; */
        top: -50px;
        /* overflow: hidden; */
    }
}

.slide__phone{
    grid-area: phone;
    text-align: center;
    z-index: 3;
    color: white;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.245);
    font-family: 'Oswald', serif;

    height: fit-content;
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: 100;
}
.slide__phone > p{
    font-size: 35px;
    margin: 0px;
}
.slide__message{
    grid-area: message;
    text-shadow: black 1px 0 10px;
    font-family: 'Oswald', serif;

    text-align: center;
    z-index: 3;
    color: white;
    margin: 0 auto;
    height: 100%;
    font-size: 45px;
    font-weight: bold;

}

.slide__contact{
    grid-area: contact;
    text-decoration: initial;
    text-align: center;
    align-self: center;
    z-index: 3;
    color: white;
    background-color: cornflowerblue;
    height: fit-content;
    width: fit-content;
    padding: 10px 30px;
    border-radius: 6px;
    margin: 0 auto;
    transition: all .2s ease-in-out;
}

.slide__contact:hover{
    background-color: rgb(68, 101, 161);
    transform: scale(1.1);

}

.slide__image[fadeinto='1']{
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: 1s;

}

.slide__arrow{
    color: white;
    z-index: 3;
    font-size: 23px;
}
.slide__arrow:hover{
    color: rgb(203, 203, 203);
}

.left__arrow{
    grid-area: leftA;
    justify-self: end;

}

.right__arrow{
    grid-area: rightA;
    justify-self: start;
}

.slideshow__overlay{
    width: 100%;
    height: 500px;
    position: absolute;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.307);

}

.authslide:hover .slideshow__overlay{
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.416);
}

/* ********* ********* EMBEDDED COMPONENT ********* ********* */
.embedded__title{
    font-family: 'Bebas Neue', serif;
    font-size: 50px;

}
.embedded__controls{
    background-color: cornflowerblue;
    z-index: 5;
}
.fa-code{
    color: white;
}

.fa-code:hover{
    color: rgb(205, 205, 205);
}

.embedded__component{
    width: fit-content;
    height: 455px;
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.fa-upload:hover{
    color: rgba(43, 255, 0, 0.939);
}
.fa-times-circle:hover{
    color: red;
}

.embedded__update__page{
    display: flex;
    z-index: 4;
    background-color: rgba(100, 148, 237, 0.645);
    width: 30%;
    justify-content: center;
    padding: 10px;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
}
.slide__up{
    animation: 1s ease-out 0s 1 slideInFromBottom;
}
@keyframes slideInFromBottom {
    0% {
        transform: translateY(100%);
    }
    100%{
        transform: translateY(0);
    }
}
.slide__down{
    animation: 1s ease-out 0s 1 slideOutFromTop;
}
@keyframes slideOutFromTop {
    100% {
        transform: translateY(100%);
    }
    0%{
        transform: translateY(0);
    }
}
.embedded__post{
    z-index: 5;
}


/* ********* ********* MEDIA STYLING ********* ********* */

@media (max-width: 1000px){
    .about__us__home{
        overflow: hidden;
        /* width: 550px; */
        width: 90%;

        font-family: 'Bebas Neue', serif;
        display: grid;
        grid-template-areas:
        "about about"
        "btitle bcontent"
        "ltitle ltitle"
        "lcontent lcontent";
        text-align: center;
    }

    .about__us__text{
        font-size: 55px;
        grid-area: about;
    }


    .about__believe__title{
        font-size: 40px;
        grid-area: btitle;
        align-self: center;
        font-family: 'Bebas Neue', serif;
        border-left: thick solid cornflowerblue;


    }
    .about__believe__content{
        font-size: 20px;
        text-align: initial;
        grid-area: bcontent;
        padding-left: 25px;
    }
    .about__location{
        grid-area: ltitle;
        font-size: 40px;
        font-family: 'Bebas Neue', serif;
        align-self: center;
        justify-self: end;
        border-right: thick solid cornflowerblue;


    }
    #google__map{
        grid-area: lcontent;
        width: 100%;
    }
}

@media (min-width: 1000px){
    .about__us__home{
        overflow: hidden;
        width: 90%;
        font-family: 'Bebas Neue', serif;
        display: grid;
        grid-template-areas:
        "about about"
        "btitle bcontent"
        "lcontent ltitle";
        text-align: center;
    }

    .about__us__text{
        font-size: 70px;
        grid-area: about;
    }
    .about__us__text:before,
    .about__us__text:after {
        background-color: rgb(255, 255, 255);
        content: "";
        display: inline-block;
        height: 1px;
        position: relative;
        vertical-align: middle;
        width: 20%;
    }

    .about__us__text:before {
        right: 0.5em;
        margin-left: -50%;
    }
    .about__us__text:after {
        left: 0.5em;
        margin-right: -50%;
      }



    .about__believe__title{
        font-size: 55px;
        grid-area: btitle;
        align-self: center;
        font-family: 'Bebas Neue', serif;
        border-left: thick solid cornflowerblue;

    }
    .about__believe__content{
        font-size: 27px;
        text-align: initial;
        grid-area: bcontent;
        text-align: center;
        /* padding-left: 60px; */
    }
    .about__location{
        grid-area: ltitle;
        border-right: thick solid cornflowerblue;

        font-size: 55px;
        font-family: 'Bebas Neue', serif;
        align-self: center;

    }
    #google__map{
        grid-area: lcontent;

    }
}
/* ********* ********* VIEWPORT TRANSITIONS ********* ********* */
.below-viewport{
    opacity: 0;
    transform: translateY(40px);

}
.below-viewport-2{
    opacity: 0;
    transform: translateX(40px);

}
.below-viewport-3{
    opacity: 0;
    transform: translateX(-40px);
}
.scroll-transition-fade{
    transition: transform 2s ease-in-out,opacity 2s ease-in-out;
}


/* ********* ********* FOOTER CSS ********* ********* */
.contact__link{
    background-color: rgba(255, 0, 0, 0);
    height: 15px;
    width: 15px;
}
.homepage__footer{
    margin-top: 100px;
    background-color: rgba(5, 5, 5, 0);
    overflow: hidden;
    width: 100%;
    height: 475px;
    font-family: 'Bebas Neue', serif;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.footer__contact__us{
    background-color: rgba(255, 255, 255, 0.166);
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 100%;
    border-radius: 6px;
    font-size: 30px;

}
.footer__message__1{
    margin-bottom: 0px;
}
.footer__message__2{
    margin-top: 10px;
}
.contact__email{
    height: 30px;
    width: 50%;
    border-radius: 5px;
    border: thin solid rgba(255, 255, 255, 0.549);
    align-self: center;
    font-family: 'Bebas Neue', serif;
    font-size: 22px;

}

.contact__message{
    margin-top: 25px;
    height: 80px;
    width: 80%;
    border-radius: 5px;
    border: thin solid rgba(255, 255, 255, 0.549);
    align-self: center;
    resize: none;
    font-family: 'Bebas Neue', serif;
    font-size: 18px;

}

.contact__submit{
    width: 150px;
    height: 35px;
    margin-top: 10px;
    align-self: center;
    background-color: cornflowerblue;
    border: thin solid rgba(255, 255, 255, 0.419);
    border-radius: 6px;
    color: white;
    font-size: 27px;
    font-family: 'Bebas Neue', serif;
}
.contact__submit:hover{
    background-color: rgb(77, 116, 189);
}

.footer__tag{
    background-color: cornflowerblue;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    margin-top: 25px;
}
.footer__tag__text{
    font-size: 25px;
    margin-bottom: 5px;
}
.footer__tag__fb{
    margin-bottom: 5px;
    margin-top: 0px;
    font-size: 25px;
    text-decoration: initial;
    color: white;
}
.footer__tag__fb:hover{
    color: rgb(179, 179, 179);
}
.email__response{
    position: fixed;
    z-index: 5;
    height: 100vh;
    width: 100vw;
}
.email__shadow{
    position: fixed;
    z-index: 6;
    background-color: rgba(0, 0, 0, 0.761);
    height: 100%;
    width: 100%;
}
.email__box{
    position: absolute;
    background-color: rgba(6, 88, 112, 0.734);
    z-index: 7;
    width: 300px;
    height: 100px;
    top: 50%;
    left: 50%;
    margin-top: -50px; /* Negative half of height. */
    margin-left: -150px; /* Negative half of width. */
    display: grid;
    grid-template-areas:
    'closeE . .'
    '. mess .'
    '. . .';
    border-radius: 6px;
}

.email__message{
    grid-area: mess;
    font-size: 20px;
    color: white;

}

.email__close{
    grid-area: closeE;
    color: red;
}
